import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <h2>Our Focus Areas</h2>
      <div className="focus-areas">
        <div className="focus-area focus-area-manufacturers">
          <div className="focus-content">
            <h3>Supporting Local Manufacturers</h3>
            <a className="focus-button" href="#manufacturers">Learn More</a>
          </div>
        </div>
        <div className="focus-area focus-area-community">
          <div className="focus-content">
            <h3>Community Support</h3>
            <a className="focus-button" href="#community">Learn More</a>
          </div>
        </div>
        <div className="focus-area">
          <div className="focus-content">
            <h3>Education & Training</h3>
            <p>We offer education and training programs to empower prosthetic professionals and users.</p>
            <a className="focus-button" href="#education">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
