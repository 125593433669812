import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-left">
        &copy; 2024
      </div>
      <div className="footer-right">
        Created with the help of OpenAI and AWS technologies
      </div>
    </footer>
  );
}

export default Footer;
