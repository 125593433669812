import React from 'react';
import Header from './Header';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <main className="App-main">
        <section id="home" className="section-home">
          <Home />
        </section>
        <section id="about" className="App-section">
          <About />
        </section>
        <section id="contact" className="App-section">
          <Contact />
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default App;
