import React from 'react';
import './Contact.css';

function Contact() {
  const handleCopyClick = () => {
    const email = "info@yourassociation.org";
    navigator.clipboard.writeText(email).then(() => {
      alert("Email copied to clipboard!");
    }, (err) => {
      console.error("Could not copy text: ", err);
    });
  };

  return (
    <section className="contact-container" id="contact">
      <div className="contact-content">
        <h2>Contact us</h2>
        <p onClick={handleCopyClick} className="copyable">Email: info@yourassociation.org</p>
        <p className="copy-hint">(Click to copy)</p>
      </div>
    </section>
  );
}

export default Contact;
